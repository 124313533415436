import React from 'react'
import { Box } from 'theme-ui'
import { FaArchive } from 'react-icons/fa'
import IconButtonCustom from '@components/IconButtonCustom'
import Section from '@components/Section'

const styles = {
  horizontal: {
    display: `flex`,
    flexWrap: `wrap`,
    overflowX: `auto`,
    // width: `auto`,
    scrollBehavior: `smooth`,
    justifyContent: 'center',
    m: -2,
    a: {
      width: [
        'calc(50% - 32px)',
        'calc(20% - 64px)',
        'calc(25% - 64px)',
        'calc(20% - 80px)'
      ],
      m: 2
    }
  }
}

const CategoryCustom = ({ variant, categories, ...props }) => (
  <Section aside={variant === 'vertical'} title='Topics' {...props}>
    <Box sx={styles[variant]}>
      {categories &&
        categories.map(({ id, name, slug, totalCount, icon }) => {
          const buttonProps = {
            key: id,
            name,
            number: totalCount,
            to: slug,
            iconPath: icon,
            Icon: !icon && FaArchive,
            variant
          }

          return totalCount !== 0 && <IconButtonCustom {...buttonProps} />
        })}
    </Box>
  </Section>
)

export default CategoryCustom

CategoryCustom.defaultProps = {
  variant: 'vertical'
}
