import React, { useEffect } from 'react'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import BannerHorizontal from '@widgets/BannerHorizontal'
import { useBlogCategories } from '@helpers-blog'
import CategoryCustom from '../../../../flow-ui/flow-ui-widgets/src/CategoryCustom'
import Hero from '../components/Hero/Hero'
import BuyMeACoffee from '../components/BuyMeACoffee/BuyMeACoffee'

const Posts = ({ data: { posts = {}, featuredPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack>
        <Main>
          <Hero />
        </Main>
      </Stack>
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <CategoryCustom
          categories={categories}
          variant='horizontal'
          omitTitle
        />
      </Stack>
      <Divider />
      <Stack>
        <Main id='featured'>
          <CardList
            nodes={featuredPosts.nodes}
            variant={['horizontal-md', 'vertical']}
            limit={6}
            columns={[1, 2, 3, 3]}
            title='Ultime ricette inserite'
          />
        </Main>
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <Divider />
          <BuyMeACoffee />
        </Main>
      </Stack>
      <Divider />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            <Stack
              title={group.categoryName}
              titleLink={group.nodes[0].category.slug}
            >
              <Main>
                <CardList
                  nodes={group.nodes}
                  variant={['horizontal-md', 'vertical']}
                  limit={6}
                  columns={[1, 2, 3, 3]}
                />
              </Main>
            </Stack>
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Divider />
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Posts
