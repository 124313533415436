import React from 'react'
import { css } from 'theme-ui'
import { Card, Button, Heading, Box } from 'theme-ui'
import NewsletterForm from '@components/NewsletterForm'
import useMailChimp from '@helpers/useMailChimp'
import { SiBuymeacoffee } from 'react-icons/si'
import { useStaticQuery, graphql, Link } from 'gatsby'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, null, `flex`],
    justifyContent: 'center',
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `3rem`,
    color: `#F3811D` //F3811D
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
}

const BuyMeACoffee = ({ simple }) => {
  const { handleSubmit, canSubmit, submitting, message, success } =
    useMailChimp()

  return (
    <Card variant='paper' sx={styles.card}>
      <Box sx={styles.wrapper}>
        {!simple && (
          <Box sx={styles.icons}>
            <SiBuymeacoffee css={css(styles.plane)} />
          </Box>
        )}
        <Heading variant='h2'>
          Vuoi supportare il nostro lavoro con un caffè?
        </Heading>

        <Box>
          <Button
            as={Link}
            to='https://www.buymeacoffee.com/ragudigitale'
            variant='primary'
            aria-label='Buy me a coffee'
          >
            Buy us a coffee
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

BuyMeACoffee.defaultProps = {
  simple: false
}

export default BuyMeACoffee
